// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-background-js": () => import("./../../../src/pages/background.js" /* webpackChunkName: "component---src-pages-background-js" */),
  "component---src-pages-coming-js": () => import("./../../../src/pages/coming.js" /* webpackChunkName: "component---src-pages-coming-js" */),
  "component---src-pages-contactme-js": () => import("./../../../src/pages/contactme.js" /* webpackChunkName: "component---src-pages-contactme-js" */),
  "component---src-pages-dissertation-js": () => import("./../../../src/pages/dissertation.js" /* webpackChunkName: "component---src-pages-dissertation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-whoami-js": () => import("./../../../src/pages/whoami.js" /* webpackChunkName: "component---src-pages-whoami-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

